import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(private auth:AuthService ,private route:Router){}
 
  canActivate(){
    
    if (localStorage.getItem('token')){
      return true;
    }
    this.route.navigate(['/login']);
    return false;
  
}
  
}
