import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from '../app/app.component';
import { LocalStorageService } from '../app/core/services';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule, MatFormFieldModule, MatCheckboxModule, MatMenuModule, MatSidenavModule, MatSidenav, } from '@angular/material';
import { Ng5SliderModule } from 'ng5-slider';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { environment } from '../environments/environment';
import { LandingComponent } from './pages/landing/landing.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ChatService } from './core/services/chat.service';
import { ChatModule } from './pages/chat/chat.module';
import { AppConfirmService } from './core/services/app-confirm/app-confirm.service';
import { AppComfirmComponent } from './core/services/app-confirm/app-confirm.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { FlatpickrModule } from 'angularx-flatpickr';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CalenderComponent } from './pages/calender/calender.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './core/services/messaging.service';
import { AsyncPipe } from '@angular/common';
import { ProfileComponent } from './pages/profile/profile.component';
import { NgxStripeModule } from 'ngx-stripe';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { TeamComponent } from './pages/team/team.component';
import { TestimonialComponent } from './pages/testimonial/testimonial.component';
import { WorkComponent } from './pages/work/work.component';
import { FeaturesComponent } from './pages/features/features.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { BlogComponent } from './pages/blog/blog.component';
import { HeaderNewComponent } from './pages/header-new/header-new.component';
import { FooterNewComponent } from './pages/footer-new/footer-new.component';
import { AgmCoreModule } from '@agm/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { SearchComponent } from './pages/search/search.component';
const config: SocketIoConfig = { url: environment.socketUrl, options: {} };
const components = [
  AppComponent,
];
// guards

@NgModule({
  declarations: [
    ...components,
    LandingComponent,
    ProfileComponent,
    AppComfirmComponent,
    CalenderComponent,
    HomeComponent,
    AboutComponent,
    TeamComponent,
    TestimonialComponent,
    WorkComponent,
    FeaturesComponent,
    PricingComponent,
    BlogComponent,
    HeaderNewComponent,
    FooterNewComponent,
    SearchComponent
  ],

  entryComponents: [
    ...components,
    AppComfirmComponent,
    CalenderComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatCheckboxModule,
    AppRoutingModule,
    CoreModule,
    PerfectScrollbarModule,
    // SearchModule,
    Ng5SliderModule,
    MatMenuModule,
    ChatModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxUiLoaderModule,
    AutocompleteLibModule,
    InfiniteScrollModule,
    NgbModalModule,
    MatCarouselModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxStripeModule.forRoot('pk_test_51IOjFUH8FPQioJubHEprnHOrA1QyKEOiokB8Ji4RlM10MUzMwzi00OGfiTonBvA0WEyYV8e6tb6O0OCi0gt0AbhX00Wpdi68ZY'),
    SocketIoModule.forRoot(config),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyCayIBisLl_xmSOmS3g524FAzEI-ZhT1sc'
    // }),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCgd-rD47NFwKVpQ30skw_D-qWUMHrxjO4',
      apiKey: 'AIzaSyD_5P0pxn1q9hvvTeCr3YCsDhLJoHwxs2c',

      libraries: ['places']
    }),
  ],
  exports:[
    HeaderNewComponent,
    BlogComponent,
  ],
  providers: [
    LocalStorageService,
    ChatService,
    AppConfirmService,
    MessagingService,
    AsyncPipe
    // ...services
  ],

  bootstrap: [AppComponent],

})
export class AppModule { }
