import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { filter } from 'rxjs/operators';
import {
  ActivatedRoute,
  Router,
  Event,
  ActivationStart,
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationEnd,
  NavigationStart
} from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service.service';
import { _getOptionScrollPosition, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material';
import { UserDataService } from '../../services/user-data.service';
import { User } from '../../models/user.model';
declare const $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLogin = false;
  bcLoadedData;
  bcForDisplay;
  user = new User;
  routeName: string;
  profileClass: string = "active";
  programClass: string = "";
  settingClass: string = "";
  insightClass: string = "";
  helpClass: string = "";
  forumClass: string = "";


  @Input()
  showMenu = false;

  @Output()
  menuClicked: EventEmitter<boolean> = new EventEmitter()
  initialUrl: any;
  _user: any = {};

  userData: any = {};
  profileProgressResponse: any;
  progressBarValue: any;
  profileProgress: string = ''


  message: string = 'Please Login First!';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 4000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  constructor(
    private router: Router,
    private auth: AuthService,
    private apiservice: ApiService,
    private userdataservice: UserDataService,

  ) {
    this.user = this.auth.currentUser();
    this.auth.userChanges.subscribe(user => this.user = user)
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.routeName = event.url;
    });
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
  }
  logo() {
    this.router.navigate(['/search']);
  }
  addProgram() {
    this.router.navigate(['/program/add']);
  }
  profile() {
    this.router.navigate(['/profile']);
  }

  getUserById() {
    this.apiservice.getUserById(this.user.id).subscribe((res: any) => {
      console.log('user by idddddddd', res);
      this.user = res;
    });
  }
  gotoChat() {
    this.router.navigate(['/chat']);
  }

  gotoCalender() {
    this.router.navigate(['/calender']);
  }

  ngOnInit() {

    this.getUserById()
  }

  logout() {
    // this.auth.logout();
    this.userdataservice.logout();
    this.router.navigate(['/login']);
  }

}
