export const environment = {
  production: true,
  apiUrl: {
    reports: "https://startupbundle.doers.online:4500/api",
    master: "https://startupbundle.doers.online:4500/api",
    stripe: "https://api.stripe.com/v1",
  },
  firebase: {
    apiKey: "AIzaSyAIakfchuhqr1IwRLtJkB7ykusBdWxwspc",
    authDomain: "startupbundle-ce267.firebaseapp.com",
    projectId: "startupbundle-ce267",
    storageBucket: "startupbundle-ce267.appspot.com",
    messagingSenderId: "556355096127",
    appId: "1:556355096127:web:9d08384a7e00bb2bf6f28a",
  },
  socketUrl: "http://93.188.167.68:4500",
  name: "prod",
};
