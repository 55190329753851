import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './pages/profile/profile.component';
import { LandingComponent } from './pages/landing/landing.component';
import { Chat2Component } from './pages/chat/chat2/chat2.component';
import { CalenderComponent } from './pages/calender/calender.component';
import { SearchComponent } from './pages/search/search.component';
import { AuthGuard } from './auth/auth.guard';
// import { ChatComponent } from './pages/chat/chat.component';
// main routes
const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingComponent },
  { path: 'profile',component: ProfileComponent, canActivate:[AuthGuard]  },
  { path: 'term-condition', loadChildren: '../app/pages/term-condition/term-condition.module#TermConditionModule' },
  { path: 'login', loadChildren: '../app/pages/login/login.module#LoginModule' },
  { path: 'forgot-password', loadChildren: '../app/pages/forgot-password/forgot-password.module#ForgotPasswordModule' },
  { path: 'sign-up', loadChildren: '../app/pages/sign-up/sign-up.module#SignUpModule' },
  { path: 'chat', canActivate:[AuthGuard] , loadChildren: '../app/pages/chat/chat.module#ChatModule' },
  // { path: 'search', loadChildren: '../app/pages/search/search.module#SearchModule' },
  { path: 'search' , component:SearchComponent, canActivate:[AuthGuard] },
  { path: 'chat2', component: Chat2Component,canActivate:[AuthGuard]  },
  { path: 'calender', component: CalenderComponent },
  { path: '**', redirectTo: 'landing' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }